import './IEDeprecation.css';

import React from "react";

import ChromeLogo from "../../../assets/img/logo-chrome.png";
import FFLogo from "../../../assets/img/logo-firefox.png";
import EdgeLogo from "../../../assets/img/logo-edge.png";
import SafariLogo from "../../../assets/img/logo-safari.png";
import OperaLogo from "../../../assets/img/logo-opera.png";

class IEDeprecation extends React.Component<{}, {}> {

    private supportedBrowser(icon: any, alt: string, link: string) {
        return (
            <a href={link} target="_blank" rel="noopener noreferrer" className="supported-browser-link">
                <img src={icon} alt={alt} width="100px"/>
            </a>
        )
    }

    public render() {
        return (
            <div className="w-full">
                <div className="bg-white">
                    <div className="max-w-7xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
                        <div className="bg-white px-4 py-5 border shadow-lg rounded-md border-gray-200 sm:px-6">
                            <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl mt-5">
                                <span className="block">Your browser is not supported.</span>
                            </h2>
                            <p className="mt-4 text-lg leading-6">Update your browser for more security,
                                speed and the best experience on this application.</p>
                            <p className="mt-4 text-lg leading-6">Please download one of these up-to-date, free and excellent browsers:</p>
                            <div className="ie-not-supported">
                                <table className="supported-browsers">
                                    <tr>
                                        <td>
                                            {this.supportedBrowser(ChromeLogo, "Google Chrome", "https://www.google.com/chrome/")}
                                        </td>
                                        <td>
                                            {this.supportedBrowser(FFLogo, "Firefox", "https://www.mozilla.org/en-US/firefox/new/")}
                                        </td>
                                        <td>
                                            {this.supportedBrowser(EdgeLogo, "Edge", "https://www.microsoft.com/en-us/edge")}
                                        </td>
                                        <td>
                                            {this.supportedBrowser(SafariLogo, "Safari", "https://support.apple.com/en_AU/downloads/safari")}
                                        </td>
                                        <td>
                                            {this.supportedBrowser(OperaLogo, "Opera", "https://www.opera.com/")}

                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default IEDeprecation;