import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import './index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import {isIE} from 'react-device-detect';
import { configure } from "mobx"
import IEDeprecation from "./common/components/ieDeprecation/IEDeprecation";

configure({
  enforceActions: "always",
  computedRequiresReaction: true,
  reactionRequiresObservable: true,
  observableRequiresReaction: true,
});

function importBuildTarget() {
  if (process.env.REACT_APP_BUILD_TARGET === "dev") {
    return import("./devPortal/DevPortal");
  } else if (process.env.REACT_APP_BUILD_TARGET === "user") {
    return import("./userPortal/UserPortal");
  } else {
    return Promise.reject(
      new Error("No such build target: " + process.env.REACT_APP_BUILD_TARGET)
    );
  }
}

if(isIE) {
  console.log("Using IE browser");
  ReactDOM.render(<IEDeprecation />, document.getElementById("root"));
} else {
  console.log("Using non IE browser");
  // Import the entry point and render it's default export
  // @ts-ignore
  importBuildTarget().then(({ default: Environment }) =>
      ReactDOM.render(
          <React.StrictMode>
            <Environment />
          </React.StrictMode>,
          document.getElementById("root")
      )
  );
}